<template>
    <div class="card p-2" style="min-height: 200px">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>Requisition Date</th>
              <th>Requisition No</th>
              <th>Project</th>
              <th>Department</th>
              <th>Total</th>
              <th>Allocation Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in requisitionList.data" :key="i">
              <td>{{ item.date }}</td>
              <td>{{ item.requisition_number }}</td>
              <td>{{ item.project.name }}</td>
              <td>{{ item.department.name }}</td>
              <td>{{ item.total_amount }}</td>
              <td>{{ item.allocation_status }}</td>
              <td>
                <button class="btn btn-primary btn-sm"
                        @click="createCS(item.id)"
                        style="cursor: pointer; margin-left: 10px"
                >
                  Create CS
                </button>
              </td>
            </tr>
            </tbody>
          </table>
          <p v-if="!requisitionList.total" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";

export default {
  name: "ListingTable",

  props: {
    requisitionList: {
      type: Object
    },
  },
  setup(){
    const route = useRoute();
    const router = useRouter();
    const createCS = (id) => {
      let param = {
        companyId: route.params.companyId,
        moduleId: route.params.moduleId,
        menuId: route.params.menuId,
        pageId: route.params.pageId,
      }

      let query = route.query;
      query.requisitionId = id;
      query.csId = '';

      router.push({
        name: 'cs-add',
        params: param,
        query: query
      })
    }

    return {
      createCS,
    }
  }
}
</script>

